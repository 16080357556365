import React from 'react'
import CardContainer from '../../components/containers/CardContainer'
import styled from '@emotion/styled'
import BlsLengthSelector from '../../containers/Bls/BlsLengthSelector/BlsLengthSelector'

const Padding = styled('div')(() =>({
    padding: 30
}))

export default function BlsSessionLength() {
  return (
    <>
        <div style={{
            maxWidth: 600,
            margin: "70px auto 0px auto"
        }}>
            <CardContainer>
                <Padding>
                    <h1>Choose your session length</h1>
                    <BlsLengthSelector/>
                </Padding>
            </CardContainer>
        </div>
    </>
  )
}
