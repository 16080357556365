import React from 'react'
import SmallContainer from '../../components/containers/SmallContainer'
import CardContainer from '../../components/containers/CardContainer'
import BlsSetup from '../../containers/MindMirrorSetup/serialPortSetup'
import StartMindMirror from '../../containers/MindMirrorSetup/startMindMirror'
import styled from '@emotion/styled'
import BlsUserId from '../../containers/Bls/BlsUserId/BlsUserId'
import BlsNav from '../../components/navigationBar/blsNav'
import { useNavigate } from 'react-router-dom'

const Padding = styled('div')(() =>({
    padding: 30
}))

export default function BlsLogin() {
    const navigate = useNavigate()
    return (
        <>
            <BlsNav
                buttonTitle={'Admin'}
                clicked={() =>{ 
                    // navigate('/admin')
                }}
            />
            <div style={{
                maxWidth: 600,
                margin: "70px auto 0px auto"
            }}>
        
                <CardContainer>
                    <Padding>
                        <h1>What is your BLS ID number?</h1>
                        <BlsUserId/>
                    </Padding>
                </CardContainer>
            </div>
        </>
    )
}