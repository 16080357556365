import { EntityNameOrEntityNameExpression } from 'typescript';
import * as actionType from '../actionTypes/mindMirror';

export const startMindMirrorStream = (payload: any) => ({
    type: actionType.START_MIND_MIRROR_STREAM,
    payload
})

export const mapMindMirrorValues = (payload: any) => ({
    type: actionType.MAP_MIND_MIRROR_VALUES,
    payload
})

export const setSerialPort = (payload: any) => ({
    type: actionType.SET_SERIAL_PORT,
    payload
})

export const setWriterPort = (payload: any) => {
    return {
        type: actionType.SET_WRITER_PORT,
        payload
    }
}

export const setReaderPort = (payload: any) => ({
    type: actionType.SET_READER_PORT,
    payload
})
