import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'

import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { cleanUpBlsSession } from '../../../store/actions/actionCreaters/bls'


interface BlsProps {
    writerPort: any
    blsUserId: string
    blsLength: number
    sessionId: string
    cleanUpBlsSession: Function
    blsCommand: number[]
    tapperSpeedReport: number
    color: number
    colorIntensity: number
    tapperIntensityReport: number
    tapperFrequencyReport: number
    active: boolean
}

function BlsFinshButton({
    writerPort,
    blsUserId,
    blsLength,
    sessionId,
    blsCommand,
    tapperSpeedReport,
    color,
    colorIntensity,
    tapperIntensityReport,
    tapperFrequencyReport,
    active,
    cleanUpBlsSession
}: BlsProps) {
    const navigate = useNavigate()

    useEffect(() => {
        cleanUpBlsSession()
    }, [])

    console.log({
        writerPort,
        blsUserId,
        blsLength,
        sessionId,
        blsCommand,
        tapperSpeedReport,
        color,
        colorIntensity,
        tapperIntensityReport,
        tapperFrequencyReport,
        active,
    })

    return (
        <PrimaryButton clicked={() => navigate('/')}>Finish</PrimaryButton>
    )
}

const mapStateToProps = (state: RootState) => ({
    writerPort: state.blsReducer.writerPort,
    blsUserId: state.blsReducer.blsUserId,
    blsLength: state.blsReducer.blsLength,
    blsCommand: state.blsReducer.blsCommand,
    tapperSpeedReport: state.blsReducer.tapperSpeedReport,
    color: state.blsReducer.color,
    colorIntensity: state.blsReducer.colorIntensity,
    tapperIntensityReport: state.blsReducer.tapperIntensityReport,
    tapperFrequencyReport: state.blsReducer.tapperFrequencyReport,
    active: state.blsReducer.active,
    sessionId: state.blsReducer.sessionId
})

const mapDispatchToProps = {
    cleanUpBlsSession
}

export default connect(mapStateToProps, mapDispatchToProps)(BlsFinshButton)
