import { combineReducers, createStore, applyMiddleware } from "redux";
import { createEpicMiddleware, combineEpics } from 'redux-observable';
import 'rxjs';

import userReducer from './reducers/user/user';
import errorReducer from './reducers/error/error';
import activeTherapy from './reducers/activeTherapy/activeTherapyReducer';
import signalCheck from './reducers/signalCheck/signalCheck';
import liveTherapyBaseline from './reducers/liveTherapyBaseline/liveTherapyBaseline';
import calibrateBaseline from './reducers/calibrateBaseline/calibrateBaseline';
import reporting from './reducers/reporting/reporting';
import binoralEEG from './reducers/binoralEEG/binoralEEG';
import simbaReducer from './reducers/simba/simbaReducer';
import sessionType from "./reducers/sessionType/sessionType";
import simbaReporting from './reducers/simba/simbaReporting';
import mindMirror from './reducers/mindMirror/mindMirror';
import blsReducer from "./reducers/blsReducer/blsReducer";

import { setupNewUser, getUserDetails} from './epics/user';
import { isTherapyActive, getActiveTherapyData, createBeforeActiveTherapy, createAfterActiveTherapy, deleteActiveTherapy, deleteAfterTherapyReport, deleteBeforeTherapyReport } from './epics/activeTherapy';
import { signalCheckWebsocket } from './epics/signalCheck';
import { therapyBaselineWebsocket } from './epics/liveTherapyBaseline';
import { 
    signalCheckRequest, 
    requestBaselineStream, 
    stopStreamRequest, 
    sendCalibrateBaselineRequest, 
    requestBinoralEEGStream, 
    sendSimbaStreamingRequest,
    cancelStreamRequest,
    sendMindMirrorStreamingRequest,
    stopVilistusStreamingRequest,
    startVilistusSimbaStreamRequest,
    signalVilistusCheckRequest
} from './epics/backendCommands';
import { calibrateBaselineWebsocket } from './epics/calibrateBaseline';
import { createNewTherapyReport, getAllTherapyReports, getTherapyReport } from './epics/reporting';
import { binoralEEGWebsocket } from './epics/binaralEEG';
import { createSimbaReport } from './epics/simba';
import { mindMirrorStream } from './epics/mindMirror';



const rootReducer = combineReducers({
    userReducer,
    errorReducer,
    activeTherapy,
    signalCheck,
    liveTherapyBaseline,
    calibrateBaseline,
    reporting,
    binoralEEG,
    simbaReducer,
    sessionType,
    simbaReporting,
    mindMirror,
    blsReducer
});

const rootEpic = combineEpics(
    setupNewUser,
    getUserDetails,
    isTherapyActive,
    getActiveTherapyData,
    signalCheckRequest,
    signalCheckWebsocket,
    requestBaselineStream,
    stopStreamRequest,
    therapyBaselineWebsocket,
    sendCalibrateBaselineRequest,
    calibrateBaselineWebsocket,
    createBeforeActiveTherapy,
    createAfterActiveTherapy,
    deleteActiveTherapy,
    createNewTherapyReport,
    getAllTherapyReports,
    getTherapyReport,
    binoralEEGWebsocket,
    requestBinoralEEGStream,
    deleteAfterTherapyReport,
    deleteBeforeTherapyReport,
    sendSimbaStreamingRequest,
    cancelStreamRequest,
    createSimbaReport,
    mindMirrorStream,
    sendMindMirrorStreamingRequest,
    stopVilistusStreamingRequest,
    startVilistusSimbaStreamRequest,
    signalVilistusCheckRequest
);

export type RootState = ReturnType<typeof rootReducer>;

const epicMiddleware = createEpicMiddleware();
const store = createStore(rootReducer, applyMiddleware(epicMiddleware));
epicMiddleware.run(rootEpic);

export default store;