export function generateHexAndDecimalColorValues(color, intensity) {
    let hexCode = returnHexColour(color, intensity)
    let decimal = convertHexStringToDecimalArray(hexCode)
    return {hexCode, decimal}
}

function returnHexColour(color, intensity) {
    intensity = isIntensityValid(intensity)
    let hexIntensity = toHex(intensity)
    let hex = createHexColor(color, hexIntensity)
    return hex
}

function isIntensityValid(intensity) {
    if(intensity > 255) {
        intensity = 255
    } else if(intensity < 0) {
        intensity = 0
    }
    return intensity
}

export function toHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function createHexColor(color, intensity) {
    switch(color) {
        case 0: return "00" + intensity + "00"
        case 1: return intensity + "0000"
        case 2: return "0000" + intensity 
        case 3: return intensity + intensity + "00" 
        case 4: return "00" + intensity + intensity
        case 5: return intensity + "00" + intensity 
        case 6: return intensity + intensity + intensity 
        default: return "000000"
    }
}

export function convertHexStringToDecimalArray(hexString) {
    var asciiValues = Array.from(hexString);
    return  asciiValues.map(function(val) {
        return val.charCodeAt(0);
    });
}

export function decimalToHex(decimalArray) {
    return String.fromCharCode(...decimalArray)
}