import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import CardContainer from '../../../components/containers/CardContainer';
import styled from '@emotion/styled'
import { saveBlsActions } from '../../../http/bls';
import Timer from '../../../components/timer/timer';
import { useNavigate } from 'react-router-dom';
import BlsControls from '../BlsControls/BlsControls';
import { cleanUpBlsSession } from '../../../store/actions/actionCreaters/bls';

interface BlsWebType {
  serialPort: any
  writerPort: any
  readerPort: any
  blsLength: number
  sessionId: string
  blsCommand: number[]
  tapperSpeedReport: number
  color: number
  colorIntensity: number
  tapperIntensityReport: number
  tapperFrequencyReport: number
  active: boolean
  cleanUpBlsSession: Function
}

export const Blslive = ({
    serialPort,
    writerPort,
    readerPort,
    blsLength,
    sessionId,
    blsCommand,
    tapperSpeedReport,
    color,
    colorIntensity,
    tapperIntensityReport,
    tapperFrequencyReport,
    active,
    cleanUpBlsSession
}: BlsWebType) => {

  const navigate = useNavigate()

  useEffect(() => {
    if(writerPort && active) {
      console.log('start')
      sendCommandToDevice()
    } else if(writerPort && !active) {
      console.log('stop')
      stopDevice()
    }
  }, [blsCommand, active])


  function sendBlsStatusToBackend() {
    let blsPacket = {
      sessionId,
      tapperSpeed: tapperSpeedReport,
      colourIntensity: colorIntensity,
      tapperIntensity: tapperIntensityReport,
      tapperFrequency: tapperFrequencyReport,
      colour: color,
      active: active,
      date: Date()
    }
    saveBlsActions(blsPacket)
  }
  
  async function sendCommandToDevice() {
      try {
          console.log('Start status')
          sendBlsStatusToBackend()
          let command = new Uint8Array(blsCommand.length)
          blsCommand.forEach((value: any, i: number) => {
            command[i] = value;
          })
          console.log(writerPort)
          await writerPort.write(command);
      } catch(error) {
          console.log(error);
      }
  }

  async function stopDevice() {
      try {
          sendBlsStatusToBackend()
          console.log('Stop status')
          let stopCommand = [2,74,50,13,10]
          let command = new Uint8Array(stopCommand.length)
          stopCommand.forEach((value: any, i: number) => {
            command[i] = value;
          })
          await writerPort.write(command);
      } catch(error) {
          console.log(error);
      }
  }

  async function closeSerialPort() {
    try {
      if(writerPort !== null && readerPort !== null && serialPort !== null) {
        await readerPort.releaseLock();
        await writerPort.close()
        await serialPort.close()
        console.log('this is getting called')
      }
    } catch(error) {
        console.log(error);
    }
  }

  return (
    <div style={{
      maxWidth: 600,
      margin: "70px auto 0px auto"
  }}>
    <CardContainer>
      <Padding>
        <Timer
          startTime={blsLength / 1000}
          pause={false}
          timerIsZero={async () => {
            await stopDevice()
            await closeSerialPort()
            setTimeout(() => {
              navigate('/bls_finish')
            }, 1000)
          }}
          statusUpdate={() => {
            console.log('Timer')
          }}
        />
        <h1>BLS Controls</h1>
        <BlsControls/>
      </Padding>
    </CardContainer>
  </div>
  )
}

const mapStateToProps = (state: RootState) => ({
    serialPort: state.blsReducer.serialPort,
    writerPort: state.blsReducer.writerPort,
    readerPort: state.blsReducer.readerPort,
    blsUserId: state.blsReducer.blsUserId,
    blsLength: state.blsReducer.blsLength,
    sessionId: state.blsReducer.sessionId,
    blsCommand: state.blsReducer.blsCommand,
    tapperSpeedReport: state.blsReducer.tapperSpeedReport,
    color: state.blsReducer.color,
    colorIntensity: state.blsReducer.colorIntensity,
    tapperIntensityReport: state.blsReducer.tapperIntensityReport,
    tapperFrequencyReport: state.blsReducer.tapperFrequencyReport,
    active: state.blsReducer.active
})

const mapDispatchToProps = {
  cleanUpBlsSession
}

export default connect(mapStateToProps, mapDispatchToProps)(Blslive)

const Padding = styled('div')(() =>({
  padding: 30
}))

