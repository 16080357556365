import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import TextField from '@mui/material/TextField';
import { setBlsUserId } from '../../../store/actions/actionCreaters/bls';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { getBlsUid } from '../../../http/bls';
import { useNavigate } from 'react-router-dom';

export const BlsUserId = ({
    setBlsUserId,
    blsUserId
}: any) => {

    const [userId, setUserId] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const navigate = useNavigate()


    // useEffect(() => {
    //     if(blsUserId !== 'none') {
    //         setBlsUserId('none')
    //         // set serial port to null as well
    //     }
    // },[])

    return (
        <>
            {error ? <div style={{
                background: '#FD9A9A',
                color: '#880D1E',
                padding: 15,
                borderRadius: 7,
                marginBottom: 20
            }}>
                <b>This is not a valid UserId</b>
            </div> : null}

            <TextField
                required
                id="outlined-required"
                label="Required"
                fullWidth
                onChange={(event) => {
                    setUserId(`${event.target.value}`)
                }}
            />
            <div style={{height: 20}}></div>
            <PrimaryButton
                clicked={async () => {
                    let uid = await getBlsUid(userId)
                    console.log(uid)
                    if(!uid) {
                        setError(true)
                    } else {
                        setError(false)
                        setBlsUserId(uid)
                        navigate('/length_selector')
                    }
                }}
            >Continue</PrimaryButton>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    blsUserId: state.blsReducer.blsUserId
})

const mapDispatchToProps = {
    setBlsUserId
}

export default connect(mapStateToProps, mapDispatchToProps)(BlsUserId)