import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import { Button } from '@mui/material'
import { setBlsLength } from '../../../store/actions/actionCreaters/bls'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { startBlsSession } from '../../../http/bls'
import { useNavigate } from 'react-router-dom'

interface BlsLengthSelector {
    setBlsLength: Function
    blsLength: number
    blsUserId: string
}

export const BlsLengthSelector = ({
    setBlsLength,
    blsLength,
    blsUserId
}: BlsLengthSelector) => {
    const navigate = useNavigate()

    let lengthOptions = [
        {length: 10000, label: '10 sec'},
        {length: 60000, label: '1 min'},
        {length: 300000, label: '5 min'},
        {length: 600000, label: '10 min'},
        {length: 1200000, label: '15 min'},
        {length: 1500000, label: '20 min'},
        {length: 1800000, label: '25 min'},
        {length: 2100000, label: '30 min'}
    ]

    let buttons = lengthOptions.map((length, i) => {
        return <Button 
            variant={blsLength === length.length ? 'contained' : 'outlined'}
            key={i} 
            onClick={() => {
                setBlsLength(length.length)
            }}
            style={{marginRight: 10}}
        >{length.label}</Button>
    })

    return (
        <>
            {buttons}
            <div style={{height: 20}}></div>
            <PrimaryButton clicked={() => navigate('/bls_connect')}>Continue</PrimaryButton>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    blsLength: state.blsReducer.blsLength,
    blsUserId: state.blsReducer.blsUserId
})

const mapDispatchToProps = {
    setBlsLength
}

export default connect(mapStateToProps, mapDispatchToProps)(BlsLengthSelector)