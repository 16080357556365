import * as actionType from '../actionTypes/userTypes';
import { UserForm } from '../../../interfaces/user';

export const signUpNewUser = (payload: UserForm) => {
    return {
        type: actionType.SIGN_UP_NEW_USER,
        payload
    }
}

export const mapUserDetails = (payload: any) => {
    return {
        type: actionType.MAP_USER_DETAILS,
        payload
    }
}

export const signInUser = (payload: string) => {
    return {
        type: actionType.SIGN_IN_USER,
        payload
    }
}

export const signOutUser = () => {
    return {
        type: actionType.SIGN_OUT_USER
    }
}

export const setSessionContext = (payload: any) => {
    return {
        type: actionType.SET_SESSION_CONTEXT, 
        payload
    }
} 




