import React from 'react'
import { primaryColor } from '../../Theme'
import { Button } from '@mui/material'
import { white } from '../../assets/colors'


interface NavProps {
    buttonTitle: String
    clicked: Function
}

export default function BlsNav({
    clicked,
    buttonTitle
}: NavProps) {
  return (
    <div style={{
        width: '100%',
        background: '#EA906C',
        height: 70,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // paddingRight: 50,
        color: white(1)
    }}>

       
    <Button onClick={() => clicked()} color={'inherit'} style={{marginRight: 30}}>{buttonTitle}</Button>
    </div>
  )
}
