import * as actionType from '../actionTypes/bls'


export const setBlsUserId = (payload: any) => {
    return {
        type: actionType.SET_BLS_USER_ID, 
        payload
    }
} 

export const setSessionId = (payload: any) => {
    return {
        type: actionType.SET_SESSION_ID, 
        payload
    }
}

export const setBlsLength = (payload: any) => {
    return {
        type: actionType.SET_BLS_LENGTH, 
        payload
    }
}




export const setBlsCommand = (payload: any) => {
    return {
        type: actionType.SET_BLS_COMMAND, 
        payload
    }
}

export const setTapperSpeedReport = (payload: any) => {
    return {
        type: actionType.SET_TAPPER_SPEED_REPORT, 
        payload
    }
}

export const setBlsColor = (payload: any) => {
    return {
        type: actionType.SET_COLOR, 
        payload
    }
}

export const setColorIntensity = (payload: any) => {
    return {
        type: actionType.SET_COLOR_INTENSITY, 
        payload
    }
}

export const setTapperIntensityReport = (payload: any) => {
    return {
        type: actionType.SET_TAPPER_INTENSITY_REPORT, 
        payload
    }
}

export const setTapperFrequencyReport = (payload: any) => {
    return {
        type: actionType.SET_TAPPER_FREQUENCY_REPORT, 
        payload
    }
}

export const setBlsActive = (payload: any) => {
    return {
        type: actionType.SET_ACTIVE, 
        payload
    }
}

export const cleanUpBlsSession = () => {
    return {
        type: actionType.CLEAN_UP_BLS_SESSION
    }
}

export const setBlsSerialPort = (payload: any) => ({
    type: actionType.BLS_SET_SERIAL_PORT,
    payload
})

export const setBlsWriterPort = (payload: any) => {
    return {
        type: actionType.BLS_SET_WRITER_PORT,
        payload
    }
}

export const setBlsReaderPort = (payload: any) => ({
    type: actionType.BLS_SET_READER_PORT,
    payload
})