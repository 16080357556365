import React from 'react'
import SetupBls from '../../containers/Bls/BlsSetup/BlsSetup'

export default function SetupBlsPage() {
  return (
    <>
        <SetupBls/>
    </>
  )
}
