import { connect } from 'react-redux'
import { RootState } from '../../../store/store'
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { black, lightGreenColor } from '../../../assets/colors';
import { cleanUpBlsSession, setBlsReaderPort, setBlsSerialPort, setBlsWriterPort } from '../../../store/actions/actionCreaters/bls';
import { read } from 'fs';

const Container = styled(Paper)(({ theme }: any) => ({
    ...theme.typography.body2,
    elevation: 3,
   padding: 20,
   margin: 10
}));

declare global {
    interface Navigator {
      serial: any;
    }
}

interface BlsDeviceSetUpProps {
    serialPort: any
    writerPort: any
    readerPort: any
    setBlsSerialPort:Function
    setBlsWriterPort: Function
    setBlsReaderPort: Function
    cleanUpBlsSession: Function
}

const BlsDeviceSetUp = ({
    serialPort,
    writerPort,
    readerPort,
    setBlsSerialPort,
    setBlsReaderPort,
    setBlsWriterPort,
    cleanUpBlsSession
}: BlsDeviceSetUpProps) => {

    const navigate = useNavigate();
    async function connectDeviceFromSerialPort() {
        try {
            let port = await navigator.serial.requestPort();
            setBlsSerialPort(port)
            await port.open({baudRate: 115200});
            let writer = await port.writable.getWriter();
            setBlsWriterPort(writer)
            let reader = await port.readable.getReader();
            setBlsReaderPort(reader)
        } catch(error) {
            console.log(error);
        }       
    }

    let connectionStatus = serialPort && readerPort && writerPort
    let connected = (
        <>
            <div style={{
                padding: 5,
                background: lightGreenColor(.5),
                border: `2px solid ${lightGreenColor(1)}`,
                borderRadius: 3,
                color: black(.5),
                fontWeight: 700,
                textAlign: 'center',
                marginBottom: 5
            }}>BLS Connected</div>
            <PrimaryButton clicked={async () => {
                navigate('/setup_bls')
            }}>Continue</PrimaryButton>
        </>
    )

  return (
    <>
        {!connectionStatus ? <PrimaryButton clicked={() => connectDeviceFromSerialPort()}>REQUEST CONNECTION</PrimaryButton> : connected}

        {/* <PrimaryButton clicked={async () => {
            try {
                if(writerPort !== null && readerPort !== null && serialPort !== null) {
                    await readerPort.releaseLock();
                    await writerPort.close()
                    await serialPort.close()
                    // cleanUpBlsSession()
                    console.log('this is getting called')
                    }
            } catch(error) {
                console.log(error);
            }
        }}>Close connection</PrimaryButton> */}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
    serialPort: state.blsReducer.serialPort,
    writerPort: state.blsReducer.writerPort,
    readerPort: state.blsReducer.readerPort
})

const mapDispatchToProps = {
    setBlsReaderPort, 
    setBlsSerialPort, 
    setBlsWriterPort,
    cleanUpBlsSession
}

export default connect(mapStateToProps, mapDispatchToProps)(BlsDeviceSetUp)