export const SET_BLS_USER_ID = 'SET_BLS_USER_ID';
export const SET_SESSION_ID = 'SET_SESSION_ID';
export const SET_BLS_LENGTH = 'SET_BLS_LENGTH';


export const SET_BLS_COMMAND = 'SET_BLS_COMMAND';
export const SET_TAPPER_SPEED_REPORT = 'SET_TAPPER_SPEED_REPORT';
export const SET_COLOR = 'SET_COLOR'
export const SET_COLOR_INTENSITY = 'SET_COLOR_INTENSITY'
export const SET_TAPPER_INTENSITY_REPORT = 'SET_TAPPER_INTENSITY_REPORT'
export const SET_TAPPER_FREQUENCY_REPORT = 'SET_TAPPER_FREQUENCY_REPORT'
export const SET_ACTIVE = 'SET_ACTIVE'

export const CLEAN_UP_BLS_SESSION = 'CLEAN_UP_BLS_SESSION'


export const BLS_SET_READER_PORT = 'BLS_SET_READER_PORT';
export const BLS_SET_WRITER_PORT = 'BLS_SET_WRITER_PORT';
export const BLS_SET_SERIAL_PORT = 'BLS_SET_SERIAL_PORT';
