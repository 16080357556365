import React from 'react'
import styled from '@emotion/styled'
import CardContainer from '../../components/containers/CardContainer'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BlsFinshButton from '../../containers/Bls/BlsFinishButton/BlsFinshButton';
import BlsNav from '../../components/navigationBar/blsNav';
import { useNavigate } from 'react-router-dom';

const Padding = styled('div')(() =>({
    padding: 30,
}))

export default function BlsFinish() {
    const navigate = useNavigate()
  return (
    <>
        <BlsNav
            buttonTitle={'Admin'}
            clicked={() => {
                // navigate('/admin')
            }}
        />
        <div style={{
            maxWidth: 600,
            margin: "70px auto 0px auto"
        }}>
            <CardContainer>
                <Padding>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <CheckCircleOutlineIcon
                            style={{ fontSize: '180px', color: 'lightgreen', margin: '10px' }}
                        />
                        <h3>BLS Session Complete!</h3>
                    </div>
                    <br/>
                    <BlsFinshButton/>
                </Padding>
            </CardContainer>
        </div>
    </>
  )
}
